import { r } from "react-select/dist/index-4bd03571.esm";
import axiosInstance from "../helpers/axiosInstance"

export const createOrGetChannel = async (domain, apiKey, apiPassword, type, storeId, extraConfig = undefined) => {
    let config = {}
    switch (type) {
        case 'shopify':
            config = {
                "domain": domain,
                "private_api_key": apiKey,
                "private_password": apiPassword
            }
            break;
        case 'vtex':
            config = {
                "app_name": domain,
                "app_key": apiKey,
                "app_token": apiPassword,
                "is_seller": extraConfig?.isSeller || false
            }
            break;
        case 'woocommerce':
            config = {
                "domain_woo": domain,
                "private_api_key": apiKey,
                "private_password": apiPassword
            }
            break;
        case 'ventiapp':
            config = {
                "app_name": domain,
                "app_user": apiKey,
                "app_password": apiPassword
            }
            break;  
        case 'walmart':
            config = {
                "app_name": domain,
                "client_id": apiKey,
                "client_secret": apiPassword
            }
            break;
        case 'liverpool':
            config = {
                "shop_id": domain,
                "access_token": apiPassword
            }
            break; 
        case 'coppel':
            config = {
                "shop_id": domain,
                "access_token": apiPassword
            }
            break; 
        case 'bling':
            config = {
                "app_name": domain,
                "access_token": apiPassword
            }
            break;
        case 'amazon':
            config = {
                "app_name": domain,
                "refresh_token": apiPassword
            }
            break;
        case 'f1commerce':
            config = {
                "domain": domain,
                "access_token": apiPassword
            }
            break;
        case 'traycorp':
            config = {
                "app_name": domain,
                "access_token": apiPassword
            }
            break;
        case 'vnda':
            config = {
                "domain": domain,
                "access_token": apiPassword
            }
            break;
        case 'stripe':
            config = {
                "access_token": apiPassword
            }
            break;
        case 'traycommerce':
            config = {
                "domain": domain,
                "shop_id": apiKey,
                "store_code": apiPassword
            }
            break;
         case 'tiny':
            config = {
                "shop_id": apiKey,
                "access_token": apiPassword
            }
            break;
        case 't1paginas':
            config = {
                "domain": domain,
                "access_token": apiPassword
            }
            break;
        default:
            break;
    }
    

    let data = {
        "type": type,
        "store_id": storeId,
        "config": config
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations`, data)
    return res
}

export const installTiendaNube = async (storeId) => {

    

    let data = {
        "store_id": storeId,
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/tiendanube/install`, data)
    return res
}

export const installBling = async (appName, storeId) => {
    let data = {
        "store_id": storeId,
        "app_name": appName
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/bling/install`, data)
    return res
}

export const reinstallTokenBling = async (channel_integration_id) => {
    let data = {
        "id": channel_integration_id
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/bling/reinstall_token`, data)
    return res
}
export const installShopee = async (storeId, autorization_type) => {

    let data = {
        "store_id": storeId,
        "autorization_type": autorization_type
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/shopee/install`, data)
    return res
}

export const installShein = async (storeId) => {

    let data = {
        "store_id": storeId,
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/shein/install`, data)
    return res
}

export const installTikTokShop = async (storeId, shopId) => {

    let data = {
        "store_id": storeId,
        "shop_id": shopId
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/tiktokshop/install`, data)
    return res
}

export const fetchChannels = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations`, { params })
    return res.data
}

export const getLocations = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${id}/locations`)
    return res.data
}
export const updateLocation = async (id, location_id, only_read_orders_from_location = undefined) => {
    let data = {
        "id_location": location_id,
    }
    if (only_read_orders_from_location !== undefined)
        data.only_read_orders_from_location = only_read_orders_from_location

    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${id}/location`,data)
    return res.data
}

export const fetchShopifyProducts = async (id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${id}/products`, { params })
    return res.data
}
export const fetchSyncProduct = async (id) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${id}/sync_inventory_shopify`)
    return res.data
}

export const updateAutomaticSync = async (id, new_status) => {

    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${id}/update-inventory-sync`, {
        "new_status": new_status
    })
    return res.data
}

export const installMeliStore = async (store_id) => {
    return await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/installation/meli`, {store_id})
}