import { isNull } from "lodash";

const  TAX_REGIMES_BR = [
  { id: "SIMPLES_NACIONAL", name: 'SIMPLES NACIONAL'},
  { id: "LUCRO_REAL", name: 'LUCRO REAL' },
  { id: "LUCRO_PRESUMIDO", name: 'LUCRO PRESUMIDO' }
];

export const ORIGIN_PRODUCT = [
  {id: "NATIONAL", name: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8'},
  {id: "FOREIGN_IMPORTED", name: '1 - Estrangeira - Importação direta, exceto a indicada no código 6'},
  {id: "FOREIGN_ACQUIRED_INTERNALLY", name: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7'},
  {id: "NATIONAL_40_70_IMPORTED", name: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%'},
  {id: "NATIONAL_MANUFACTURED", name: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes'},
  {id: "NATIONAL_0_40_IMPORTED", name: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%'},
  {id: "FOREIGN_DIRECT_IMPORT", name: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural'},
  {id: "FOREIGN_ACQUIRED_INTERNALLY_WITHOUT_NATIONAL_SIMILAR", name: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural'},
  {id: "NATIONAL_70_IMPORTED", name: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%'},
]

const  TAX_SITUATION_SIMPLES_NACIONAL = [

  { id: "101", name: '101 - Tributada com permissão de crédito' },
  { id: "102", name: '102 - Tributada sem permissão de crédito' },
  { id: "103", name: '103 - Isenção do ICMS para faixa de receita bruta' },
  { id: "201", name: '201 - Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária' },
  { id: "202", name: '202 - Tributada sem permissão de crédito e com cobrança do ICMS por substituição tributária'},
  { id: "203", name: '203 - Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por substituição tributária' },
  { id: "300", name: '300 - Imune' },
  { id: "400", name: '400 - Não tributada' },
  { id: "500", name: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação' },
  { id: "900", name: '900 - Outros' },
]

const  TAX_SITUATION_ISENTO_SIMPLES_NACIONAL = [
  { id: "300", name: '300 - Imune' }
]

const  TAX_SITUATION_LUCRO = [
  { id: "00", name: '00 - Tributada integralmente' },
  { id: "10", name: '10 - Tributada e com cobrança do ICMS por substituição tributária' },
  { id: "20", name: '20 - Com redução da base de cálculo' },
  { id: "30", name: '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária' },
  { id: "40", name: '40 - Isenta' },
  { id: "41", name: '41 - Não tributada' },
  { id: "50", name: '50 - Suspensão' },
  { id: "51", name: '51 - Diferimento' },
  { id: "60", name: '60 - ICMS cobrado anteriormente por substituição tributária' },
  { id: "70", name: '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária' },
  { id: "900", name: '900 - Outros' },
];

const  TAX_SITUATION_ISENTO_LUCRO = [
  { id: "40", name: '40 - Isenta' },
  { id: "41", name: '41 - Não tributada' }
];

const  TAX_SITUATION_PIS = [
  { id: "01", name: '01 - Operação Tributável - Base de Cálculo = Valor da Operação Alíquota Normal (Cumulativo/Não cumulativo' },
  { id: "02", name: '02 - Operação Tributável - Base de Cálculo = Valor da Operação (Alíquota diferenciada)' },
  { id: "03", name: '03 - Operação Tributável - Base de Cálculo = Quantidade Vendida X Alíquota por Unidade de Produto' },
  { id: "04", name: '04 - Operação Tributável - Tributação Monofásica - (Alíquota Zero)' },
  { id: "05", name: '05 - Operação Tributável - (Substituição Tributária)' },
  { id: "06", name: '06 - Operação Tributável (Alíquota Zero)' },
  { id: "07", name: '07 - Operação Isenta de Contribuição' },
  { id: "08", name: '08 - Operação sem Incidência da Contribuição' },
  { id: "09", name: '09 - Operação com Suspensão da Contribuição' },
  { id: "49", name: '49 - Outras Operações de Saída' },
  { id: "50", name: '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
  { id: "51", name: '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno'},
  { id: "52", name: '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação'},
  { id: "53", name: '53 - Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
  { id: "54", name: '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
  { id: "55", name: '55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
  { id: "56", name: '56 - Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'},
  { id: "60", name: '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
  { id: "61", name: '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'},
  { id: "62", name: '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação'},
  { id: "63", name: '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
  { id: "64", name: '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
  { id: "65", name: '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
  { id: "66", name: '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'},
  { id: "67", name: '67 - Crédito Presumido - Outras Operações'},
  { id: "70", name: '70 - Operação de Aquisição sem Direito a Crédito'},
  { id: "71", name: '71 - Operação de Aquisição com Isenção'},
  { id: "72", name: '72 - Operação de Aquisição com Suspensão'},
  { id: "73", name: '73 - Operação de Aquisição a Alíquota Zero'},
  { id: "74", name: '74 - Operação de Aquisição sem Incidência da Contribuição'},
  { id: "75", name: '75 - Operação de Aquisição por Substituição Tributária'},
  { id: "98", name: '98 - Outras Operações de Entrada'},
  { id: "99", name: '99 - Outras Operações'},
]
const  TAX_SITUATION_IPI = [
  { id: "00", name: '00 - Entrada com recuperação de crédito'},
  { id: "01", name: '01 - Entrada tributada com alíquota zero'},
  { id: "02", name: '02 - Entrada isenta'},
  { id: "03", name: '03 - Entrada não-tributada'},
  { id: "04", name: '04 - Entrada imune'},
  { id: "05", name: '05 - Entrada com suspensão'},
  { id: "49", name: '49 - Outras entradas'},
  { id: "50", name: '50 - Saída tributada'},
  { id: "51", name: '51 - Saída tributada com alíquota zero'},
  { id: "52", name: '52 - Saída isenta'},
  { id: "53", name: '53 - Saída não-tributada'},
  { id: "54", name: '54 - Saída imune'},
  { id: "55", name: '55 - Saída com suspensão'},
  { id: "99", name: '99 - Outras saídas'},
]
export  const tax_cpof_donation_same_state= [
 {id: "5.949", name: "5.949"},
 {id: "5.910", name: "5.910"},
 {id: "5.911", name: "5.911"},
 {id: "5.912", name: "5.912"},
 { id: "5.117", name: "5.117"},

]
export  const tax_cpof_donation_other_state= [
  {id: "6.949", name: "6.949"},
  {id: "6.910", name: "6.910"},
  {id: "6.911", name: "6.911"},
  {id: "6.912", name: "6.912"},
  { id: "6.117", name: "6.117"},
]

export  const tax_cpof_donation_return_same_state= [
     { id: "1.949", name: "1.949"},
     { id: "1.913", name: "1.913"},
     { id: "1.914", name: "1.914"}

  ]
export  const tax_cpof_donation_return_other_state= [
    { id: "2.949", name: "2.949"},
    { id: "2.913", name: "2.913"},
    { id: "2.914", name: "2.914"}
  ]

export  const tax_cpof_same_state= [
    { id: "5.101", name: "5.101"},
    { id: "5.102", name: "5.102"},
    { id: "5.103", name: "5.103"},
    { id: "5.104", name: "5.104"},
    { id: "5.105", name: "5.105"},
    { id: "5.106", name: "5.106"},
    { id: "5.109", name: "5.109"},
    { id: "5.110", name: "5.110"},
    { id: "5.401", name: "5.401"},
    { id: "5.405", name: "5.405"},
    { id: "5.910", name: "5.910"}
  ]
export  const tax_cpof_other_state= [

    { id: "6.101", name: "6.101"},
    { id: "6.102", name: "6.102"},
    { id: "6.103", name: "6.103"},
    { id: "6.104", name: "6.104"},
    { id: "6.105", name: "6.105"},
    { id: "6.106", name: "6.106"},
    { id: "6.107", name: "6.107"},
    { id: "6.108", name: "6.108"},
    { id: "6.109", name: "6.109"},
    { id: "6.110", name: "6.110"},
    { id: "6.401", name: "6.401"},
    { id: "6.403", name: "6.403"},
    { id: "6.910", name: "6.910"}
  ]

export  const tax_cpof_international = [
  { id: "7.102", name: "7.102"}
]
export  const tax_cpof_replenishment_same_state= [
    { id: "5.949", name: '5.949' }
  ]
export  const tax_cpof_replenishment_other_state= [
    { id: "6.949", name: '6.949' }
  ]
export  const tax_cpof_return_same_state= [
    { id: "1.201", name: "1.201"},
    { id: "1.202", name: "1.202"},
    { id: "1.203", name: "1.203"},
    { id: "1.204", name: "1.204"},
    { id: "1.411", name: "1.411"}
  ]

export  const tax_cpof_return_other_state= [
  { id: "1.201", name: "1.201"},
  { id: "1.202", name: "1.202"},
  { id: "2.202", name: "2.202"}
]
export  const tax_aliquota= [
    { id: "0.00", name: '0.00' },
  ]
export  const framing_code= [
    { id: "999", name: '999' },
    { id: "001", name: '001' },
    { id: "345", name: '345' },
  ]

export const getTaxes = (id, type) => {
  let tax_values= []
  switch (type) {
    case "simbolic_return_same_state":
    case "donation_return_same_state":
      tax_values = tax_cpof_donation_return_same_state
      break;
    case "simbolic_return_other_state":
    case "donation_return_other_state":
      tax_values = tax_cpof_donation_return_other_state
      break;
    case "same_state":
      tax_values = tax_cpof_same_state
      break;
    case "other_state":
      tax_values = tax_cpof_other_state
      break;
    case "international":
      tax_values = tax_cpof_international
      break;
    case "replenishment_same_state":
      tax_values = tax_cpof_replenishment_same_state
      break;
    case "replenishment_other_state":
      tax_values = tax_cpof_replenishment_other_state
      break;
    case "donation_same_state":
      tax_values = tax_cpof_donation_same_state
      break;
    case "donation_other_state":
      tax_values = tax_cpof_donation_other_state
      break;
    case "return_same_state":
      tax_values = tax_cpof_return_same_state
      break;
    case "return_other_state":
      tax_values = tax_cpof_return_other_state
      break;
    case "tax_aliquota":
      tax_values = tax_aliquota
      break;
    case "framing_code":
      tax_values = framing_code
      break;
      default:
      return null
  }

  let foundIndex = tax_values.findIndex(def => def.id === id)
  if (foundIndex === -1) {
    return null
  }
  else {
    return tax_values[foundIndex]
  }

}

export const getOrigin = (origin) => {
  let foundIndex = ORIGIN_PRODUCT.findIndex(def => def.id === origin)
  if (foundIndex === -1) {
    return null
  }
  else {
    return ORIGIN_PRODUCT[foundIndex]
  }
}

export const getTaxRegimen = (tax_regime = "") => {
  let foundIndex = TAX_REGIMES_BR.findIndex(def => def.id === tax_regime)
  if (foundIndex === -1) {
    return "Regimen sin configurar"
  }
  else {
    return TAX_REGIMES_BR[foundIndex].name
  }
}

export const getTaxSituationCfop = (tax_regime, cfop, get_name = false) => {
  let tax_situation = []
  if (tax_regime === "SIMPLES_NACIONAL") {
    tax_situation =  TAX_SITUATION_SIMPLES_NACIONAL
  }
  else {
    tax_situation =  TAX_SITUATION_LUCRO
  }
  let foundIndex = tax_situation.findIndex(def => def.id === cfop)
  if (foundIndex === -1) {
    return "No hay impuesto"
  }
  else {
    if(get_name) return tax_situation[foundIndex].name
    return tax_situation[foundIndex]
  }
}
export const getTaxSituationPis = (pis, get_name = false) => {

  let foundIndex = TAX_SITUATION_PIS.findIndex(def => def.id === pis)
  if (foundIndex === -1) {
    return "No hay impuesto"
  }
  else {
    if(get_name) return TAX_SITUATION_PIS[foundIndex].name
    return TAX_SITUATION_PIS[foundIndex]
  }
}
export const getTaxSituationIpi = (ipi, get_name = false) => {

  let foundIndex = TAX_SITUATION_IPI.findIndex(def => def.id === ipi)
  if (foundIndex === -1) {
    return "No hay impuesto"
  }
  else {
    if(get_name) return TAX_SITUATION_IPI[foundIndex].name
    return TAX_SITUATION_IPI[foundIndex]
  }
}


export const getTaxSituation = (tax_regime, tax) => {
  switch (tax) {
    case "ICMS":
      if (tax_regime === "SIMPLES_NACIONAL") {
        return TAX_SITUATION_SIMPLES_NACIONAL
      }
      else {
        return TAX_SITUATION_LUCRO
      }
    case "ICMS_ISENTO":
      if (tax_regime === "SIMPLES_NACIONAL") {
        return TAX_SITUATION_ISENTO_SIMPLES_NACIONAL
      }
      else {
        return TAX_SITUATION_ISENTO_LUCRO
      }
    case "COFINS":
      return TAX_SITUATION_PIS
    case "PIS":
      return TAX_SITUATION_PIS
    case "IPI":
      return TAX_SITUATION_IPI
    default:
      break;
  }
}