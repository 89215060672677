export const ROOT = '/'
export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const FORGOT_PASSWORD = '/forgot-password'

export const DASHBOARD = '/dashboard'

export const BLING = '/bling'
export const SHOPIFY_ONBOARDING = '/shopify-onboarding'

export const ORDERS = '/orders'
export const SHOW_ORDERS = '/orders/show/:id'
export const CREATE_ORDER_BULK_TRACKER = '/orders/create/track/bulk/:id'

export const ADD_ORDER = '/orders/add'
export const EDIT_ORDER = '/orders/edit/:id'
export const DUPLICATE_ORDER = '/orders/duplicate/:id'

export const INVENTORY = '/inventory'
export const ADD_PRODUCT = '/inventory/add-product'
export const PRODUCT_BULK_CREATION_TRACKER = '/inventory/bulk-create/:batch_id'
export const ADD_KIT = '/inventory/add-kit'
export const EDIT_PRODUCT = '/inventory/edit-product/:id'
export const VIEW_LOTS = '/inventory/lots/:id'
export const EDIT_KIT = '/inventory/edit-kit/:id'

export const INVOICES = '/invoices'
export const INVOICE_ORDER = '/invoices/orders/:id'
export const INVOICE_ORDERS = '/invoices/orders'
export const INVOICE_REPLENISHMENT = '/invoices/replenishments/:id'
export const INVOICE_REPLENISHMENTS = '/invoices/replenishments'
export const INVOICE_REPLENISHMENT_INFO = '/invoices/replenishments/info/:id'
export const INVOICE_RETURNS = '/invoices/returns'
export const INVOICE_RETURN = '/invoices/returns/:id'
export const INVOICE_CNABS = '/invoices/cnabs'
export const INVOICE_CNABS_SHOW = '/invoices/cnabs/show/:id'

export const INTEGRATIONS = '/integrations'
export const REPLENISHMENTS = '/replenishments'
export const ADD_REPLENISHMENT = '/replenishments/add' 
export const EDIT_REPLENISHMENT = '/replenishments/edit/:id'
export const SHOW_REPLENISHMENT = '/replenishments/show/:id'
export const SHOW_REPLENISHMENT_INFO = '/replenishments/show_info/:id'
export const ADD_PRODUCT_REPLENISHMENT = '/replenishments/add/products'
export const IMPORT_SHOPIFY_PRODUCTS = '/integrations/:id/shopify/import-products'
export const IMPORT_VTEX_PRODUCTS = '/integrations/:id/vtex/import-products'
export const SHIPPING_METHODS = '/integrations/:id/shipping-methods'
export const AVAILABLE_INTEGRATIONS = '/integrations/available'
export const ADD_SHOPIFY_INTEGRATION = '/add-integration/shopify-private'
export const ADD_AMAZON_INTEGRATION = '/add-integration/amazon'
export const ADD_SHOPIFY_PUBLIC_INTEGRATION = '/add-integration/shopify'
export const ADD_VTEX_INTEGRATION = '/add-integration/vtex'
export const ADD_WOOCOMMERCE_INTEGRATION = '/add-integration/woocommerce'
export const ADD_TIENDANUBE_INTEGRATION = '/add-integration/tiendanube'
export const ADD_BLING_INTEGRATION = '/add-integration/bling'
export const ADD_VENTIAPP_INTEGRATION = '/add-integration/ventiapp'
export const ADD_WALMART_INTEGRATION = '/add-integration/walmart'
export const ADD_MELI_INTEGRATION = '/add-integration/meli'
export const ADD_LIVERPOOL_INTEGRATION = '/add-integration/liverpool'
export const ADD_COPPEL_INTEGRATION = '/add-integration/coppel'
export const ADD_TRAYCORP_INTEGRATION = '/add-integration/traycorp'
export const ADD_VNDA_INTEGRATION = '/add-integration/vnda'
export const ADD_TRAY_INTEGRATION = '/add-integration/tray'
export const ADD_TINY_INTEGRATION = '/add-integration/tiny'
export const ADD_STRIPE_INTEGRATION = '/add-integration/stripe'
export const ADD_SHOPEE_INTEGRATION = '/add-integration/shopee'
export const ADD_SHEIN_INTEGRATION = '/add-integration/shein'
export const ADD_F1COMMERCE_INTEGRATION = '/add-integration/f1commerce'
export const ADD_T1PAGINAS_INTEGRATION = '/add-integration/t1paginas'
export const ADD_TIKTOK_INTEGRATION = '/add-integration/tiktokshop'
export const CLAIMS = '/claims'
export const SHOW_CLAIM = '/claims/:id'
export const CLAIM_CREATED = '/orders/:order_id/claim/create/success'
export const CREATE_CLAIM = '/orders/:order_id/claim/create'
export const WORK_ORDERS = '/work-orders'
export const ADD_WORK_ORDER = '/work-orders/add'
export const SHOW_WORK_ORDER = '/work-orders/detail/:id'

export const STORES = '/stores'
export const STORE_PATH = '/stores/:storeId'
export const STORE_DETAIL = '/stores/:id/detail'
export const STORE_LATEST_STATEMENT_FILE = '/stores/:storeId/latest-billing-statement/:attachmentName'

export const RETURNS = '/returns'
export const REVIEW_EXTERNAL_RETURN = '/returns/review/externally-requested/:return_id'
export const SHOW_ORDER_RETURNS = '/returns/show-order/:id'
export const SHOW_RETURN = '/returns/:id'
export const CREATE_RETURN = '/orders/:order_id/return'

export const ADD_ORDER_REMOVE_STOCK = '/orders/remove_stock/add'
export const EDIT_ORDER_REMOVE_STOCK = '/orders/remove_stock/edit/:id'
export const REMOVE_STOCK_CREATED = '/orders/remove_stock/create/success'
export const SHOW_RETURN_ORDERS = '/orders/show-return/:id'

export const BILLING = '/billing'

export const SETTINGS = '/settings'
export const SETTINGS_USERS = '/settings/users'
export const SETTINGS_AUTOMATIC_REPORTS = '/settings/automatic-reports'
export const SETTINGS_AUTOMATIC_REPORT_EDIT = '/settings/automatic-reports/edit/:report_id'
export const SETTINGS_AUTOMATIC_REPORT_ADD = '/settings/automatic-reports/add'
export const SETTINGS_ALERTS = '/settings/alerts'
export const SETTINGS_TAXES = '/settings/taxes'
export const SETTINGS_AUTOMATION = '/settings/automation'
export const SETTINGS_BUSINESS_RULES= '/settings/business-rules'
export const SETTINGS_TRACKING_NOTIFICATIONS= '/settings/tracking-notifications'
export const SETTINGS_BRAND_IDENTITY = '/settings/brand-identity'
export const SETTINGS_PACKING_OPTIONS = '/settings/packing-options'

export const CREATE_BUSINESS_RULE = '/settings/create-business-rule'
