import { useMemo, useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PencilIcon, DocumentDuplicateIcon, PauseIcon, XCircleIcon, LightBulbIcon, RefreshIcon, CurrencyDollarIcon, TruckIcon, InformationCircleIcon, PlusCircleIcon} from '@heroicons/react/outline';
import { Tooltip } from 'antd';

import { FormattedRelativeTime } from './FormattedRelativeTime';
import { FormattedDate } from './FormattedDate';
import { SHOW_RETURN_ORDERS, STORE_PATH, SHOW_ORDERS } from "../navigation/constants";
import { payOrder, holdOrder, unholdOrder } from "../services/orderServices";
import { ConfirmDialog } from "./ConfirmDialog";
import { LINE_TYPE_END, LINE_TYPE_MIDDLE, LINE_TYPE_START, LINE_TYPE_NONE, TimelineItem, DotIcon } from "./Timeline/TimelineItem";
import SkeletonLoader from './SkeletonLoader';
import { ClaimIcon } from './Icons/ClaimIcon';
import { FlagIcon } from './Icons/FlagIcon';
import { buildMessageForOrderHistoryError, ORDER_ERRORS } from '../pages/Orders/OrderErrorUtils';
import { TRACKING_DESCRIPTIONS, DELIVERY_ATTEMPT_DESCRIPTIONS, DEPRECATED_SHIPPING_LABEL_CATEGORIES } from "../pages/Orders/constants";

// Available icons list
const icons = {
    // Normal icons
    DOT: "DOT",
    COPY: "COPY",
    CLOSE: "CLOSE",
    BULB: "BULB",
    PAUSE: "PAUSE",
    PLAY: "PLAY",
    EDIT: "EDIT",
    FLAG: "FLAG",
    ROLLBACK: "ROLLBACK",
    MONEY: "MONEY",
    TRUCK: "TRUCK",
    NEW: "NEW",

    // Bold icons
    BOLD_DOT: "BOLD_DOT",
    BOLD_COPY: "BOLD_COPY",
    BOLD_CLOSE: "BOLD_CLOSE",
    BOLD_BULB: "BOLD_BULB",
    BOLD_PAUSE: "BOLD_PAUSE",
    BOLD_PLAY: "BOLD_PLAY",
    BOLD_EDIT: "BOLD_EDIT",
    BOLD_FLAG: "BOLD_FLAG",
    BOLD_ROLLBACK: "BOLD_ROLLBACK",
    BOLD_MONEY: "BOLD_MONEY",
    BOLD_TRUCK: "BOLD_TRUCK",
    BOLD_NEW: "BOLD_NEW",
}

// Order history statuses
const statuses = {
    INITIAL: "initial",
    EMPTY: "empty",
    PENDING: "pending",
    ERROR: "error",
    RETURNING: "returning",
    RETURNED: "returned",
    REENTERED: "reentered",
    RETURN_ARRIVED: "return_arrived",
    SHIPMENT_CANCELED: "shipment_canceled",
    SHIPMENT_DELIVERED: "shipment_delivered",
    SHIPMENT_IN_TRANSIT: "shipment_in_transit",
    SHIPMENT_RETURNED: "shipment_returned",
    SHIPMENT_TO_BE_SELF_COLLECTED: "shipment_to_be_self_collected",
    SHIPMENT_WITH_INCIDENT: "shipment_with_incident",
    SHIPMENT_OUT_FOR_DELIVERY: "shipment_out_for_delivery",
    SHIPPED: "shipped",
    PROCESSING: "processing",
    CANCELATION_WINDOW: "cancelation_window",
    CANCELED: "canceled",
    BACKORDER: "backorder",
    UNPAID: "unpaid",
    HOLD: "holded",
    INTERRUPTED: "interrupted",
    SHIPMENT_INTERRUPTED: "shipment_interrupted",
    ENTIRELY_DROPSHIPPING: "entirely_dropshipping",
    SHIPMENT_COLLECTED: "shipment_collected",
}

// Order history actions
const actions = {
    CREATE: "create",
    EDIT: "edit",
    DUPLICATE: "duplicate",
    CREATE_FROM: "create_from",
    CANCEL: "cancel",
    ORDER_STATUS_UPDATE: "order_status_update",
    TRACKING_UPDATE: "tracking_update",
    RETURN: "return",
    UNHOLD: "unhold",
    PAID: "paid",
    RESET_PENDING: "reset_pending",
}

// Actions that are not originally included in the order history, such as "claims" or "shipping incidents"
const virtualActions = {
    SHIPPING_INCIDENT: "shipping_incident",
    CLAIM: "claim",
}

// Order history origins
const origins = {
    CUBBO: "cubbo",
    INTEGRATION_WEBHOOK: "integration_webhook",
    SHIPPING_WEBHOOK: "shipping_webhook",
    TRACKING_WATCHER: "tracking_watcher",
    CUBBO_USER: "cubbo_user",
    CUBBO_USER_IMPORT: "cubbo_user_import",
    CUBBO_BUSINESS_RULE: "cubbo_business_rule",
    CUBBO_AUTOMATION: "cubbo_automation",
    CUBBO_TRACKING_APP: "tracking_app"
}

// Status colors, depending of the status a different color will be used
const statusColors = {
    PENDING: "PENDING",
    IN_REVIEW: "IN_REVIEW",
    REJECTED: "REJECTED",
    RESOLVED: "RESOLVED",
}

// Button action types
const buttonActions = {
    PAY_ORDER: "pay_order",
    HOLD_ORDER: "hold_order",
    UNHOLD_ORDER: "unhold_order",
    VIEW_RETURN: "view_return",
}

const translationPrefix = "orders.orderHistory.timelineElements"

// Custom icons component, mainly used for element timeline icon
const CustomIcon = ({icon, isSmall = true}) => {
    const isBold = icon.startsWith("BOLD_")
    const historyIcon = isBold ? icon.substring(5) : icon; // Removes "BOLD_" prefix when required

    switch (historyIcon) {
        case icons.DOT:
            return <DotIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.COPY:
            return <DocumentDuplicateIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.CLOSE:
            return <XCircleIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.BULB:
            return <LightBulbIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.PAUSE:
            return <PauseIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.PLAY:
            return <RefreshIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.EDIT:
            return <PencilIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.FLAG:
            return <FlagIcon className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.ROLLBACK:
            return <ClaimIcon className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.MONEY:
            return <CurrencyDollarIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.TRUCK:
            return <TruckIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        case icons.NEW:
            return <PlusCircleIcon fill="white" className={`${isSmall ? "w-5 h-5": "w-6 h-6"} ${isBold ? "text-gray-800" : "text-gray-300"}`} />
        default:
            return <></>
    }
}

// Text with a hyperlink component
const TextWithLink = ({text, wordToLink, link, replaceWithWord=null, linkStyle=null, dotEnd=false, isLocalLink=false}) => {
    const history = useHistory()

    if(!text || text === "") { return <></> }

    const words = text.split(" ")
    const indexOfWordToLink = words.indexOf(wordToLink)

    if(indexOfWordToLink === -1) { return <>{text}</> }

    const preText = words.slice(0, indexOfWordToLink).join(" ")
    const postText = words.slice(indexOfWordToLink + 1).join(" ")
    const endText = !dotEnd || postText.endsWith(".") ? "" :  "."

    const linkComponent = isLocalLink ? (
            <span
                className="font-normal text-sm hover:underline cursor-pointer text-blue-500"
                onClick={(e) => {
                    history.push(link)
                }}>
                    { replaceWithWord ? replaceWithWord : wordToLink}
            </span>
        ) : (
            <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className={linkStyle}>
                    { replaceWithWord ? replaceWithWord : wordToLink}
            </a>
        )

    const wordsWithLink = [
        <>{preText}</>,
        <>{preText && preText !== "" ? " " : ""}</>,
        linkComponent,
        <>{postText && postText !== "" ? " " : ""}</>,
        <>{postText}</>,
        <>{endText}</>
    ]

    return <>{wordsWithLink}</>
}

// Action Buttons components, to perform actions such as "pay order" or "hold order"
const ActionButton = ({buttonAction, orderId, orderReturnId, dialog, setDialog=()=>{} }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })

    const buttonStyles = "block border border-gray-300 shadow-sm rounded-md p-1.5 text-gray-700 font-normal hover:bg-gray-100 hover:text-gray-700 hover:border-blue-400 max-w-max"

    switch (buttonAction) {
        case buttonActions.PAY_ORDER:
            return (
                <button
                    className={buttonStyles}
                    onClick={(e) =>  {
                        e.stopPropagation()
                        setDialog({
                            ...dialog,
                            open: true,
                            title: t("orders.list.pay_order"),
                            description: t("orders.list.sure_to_pay"),
                            type: "paid",
                            order_id: orderId,
                        })}
                    }
                >
                    {t("orders.list.pay_order")}
                </button>
            )

        case buttonActions.HOLD_ORDER:
            return (
                <button
                    className={buttonStyles}
                    onClick={(e) =>  {
                        e.stopPropagation()
                        setDialog({
                            ...dialog,
                            open: true,
                            title: t("orders.list.pause_order"),
                            description: t("orders.list.sure_to_pause"),
                            type: "hold",
                            order_id: orderId,
                        })}
                    }
                >
                    {t("orders.list.pause_order_action")}
                </button>
            )

        case buttonActions.UNHOLD_ORDER:
            return (
                <button
                    className={buttonStyles}
                    onClick={(e) =>  {
                        e.stopPropagation()
                        setDialog({
                            ...dialog,
                            open: true,
                            title: t("orders.list.reset_order"),
                            description: t("orders.list.sure_to_reboot"),
                            type: "unhold",
                            order_id: orderId,
                        })}
                    }
                >
                    {t("orders.list.reset_order")}
                </button>
            )

        case buttonActions.VIEW_RETURN:
            return (
                <button className={buttonStyles}
                    onClick={(e) =>  {
                        e.stopPropagation()
                        history.push(storePath + SHOW_RETURN_ORDERS.replace(":id", orderReturnId))
                    }
                }>
                    {t("orders.list.view_return")}
                </button>
            )

        default:
            return <></>
    }
}

// Order History Element component
const HistoryElement = ({
    orderId,
    orderReturnId,
    dialog,
    setDialog,

    icon,
    lineType,
    dateTime,

    createdByName = null, // Who created the action, example: "Cubbo"
    showCreatedByName = false, // Show actionBy text

    title, // Title text
    lightTitle = false, // Set a lighter title color.
    italicTitle = false, // Set a cursive title font.

    titleComplement = null, // Complement text to add to title at the end of date time. Example: "Order 123"
    titleComplementWordToLink = null, // Word to be replaced where "{link}" text is in titleComplement text. Example: "here"
    titleComplementLink = null, // Link to add to title complement word. Example: "https://cubbo.com"
    isTitleComplementLinkLocal = false, // Is local link (e.g. "/orders/123")

    description, // Description text
    descriptionWordToLink = null, // Word to be replaced where "{link}" text is in description text. Example: "here"
    descriptionLink = null, // Link to add to description. Example: "https://cubbo.com"
    isDescriptionLinkLocal = false, // Is local link (e.g. "/orders/123")
    italicDescription = false, // Set a cursive description font.
    lightDescription = false, // Set a lighter description color.

    showParagraph = false, // Show paragraph
    paragraphTitle = null, // Title for paragraph text
    paragraphIcon = null, // Icon to add to paragraph text.
    paragraphSubtitles = null, // Array of paragraph subtitles, must be of same length as paragraphContents. Set a position to null to not show subtitle in that position.
    paragraphContents = null, // Array of paragraph text contents, must be of same length as paragraphSubtitles. Set a position to null to not show content in that position.
    paragraphAsDescriptionStyle = false, // Set description styles for paragraph text.

    showRelativeTime = false, // Show relative time (e.g. "2 days ago")
    showAbsoluteTime = true, // Show absolute time (e.g. "1 Dic 23 at 12:00")

    actionButtonToShow = null, // Show action button, (buttonActions)

    actionStatusBoxMode = false, // Show action status box style
    actionStatusBoxStatusColor = statusColors.PENDING, // Color of action status text
    actionStatusBoxStatusResult = "", // Result of action status text
    actionStatusBoxStatusDateTime = null, // Date and time of the action status

    createdByNameAfterTitleComplement = false, // Show created by name after all text
    datesAfterAll = false, // Show dates after all text

    titleTooltipMessage = null, // Tooltip message for title

    paragraphArray = null, // Array of strings (paragraphs)
}) => {
    const { t } = useTranslation()
    const iconComponent = <CustomIcon icon={icon} />
    const paragraphIconComponent = (showParagraph && paragraphIcon) ? <CustomIcon icon={paragraphIcon} isSmall={false}/> : null

    const titleComplementComponent = titleComplementLink ? (
        <TextWithLink
            text={titleComplement}
            wordToLink={"{link}"}
            link={titleComplementLink}
            replaceWithWord={titleComplementWordToLink}
            isLocalLink={isTitleComplementLinkLocal}
        />
    ) : titleComplement ? <>{titleComplement}</> : <></>

    const descriptionComponent = descriptionLink ? (
        <TextWithLink
            text={description}
            wordToLink={"{link}"}
            link={descriptionLink}
            replaceWithWord={descriptionWordToLink}
            dotEnd={true}
            isLocalLink={isDescriptionLinkLocal}
        />
    ) : <>{description + "."}</>

    const buildParagraphs = () => {
        if(paragraphSubtitles?.length !== paragraphContents?.length) { return <></> }

        return paragraphSubtitles.map((subtitle, index) => {
            const paragraph = paragraphContents[index]

            const parsedSubtitle = subtitle?.endsWith(".") ? subtitle : subtitle + "."
            const parsedParagraph = paragraph?.endsWith(".") ? paragraph : paragraph + "."

            return (
                <div className={"text-sm flex flex-col gap-1"}>
                    {!!subtitle && (
                        <p className={`m-0 font-semibold`}>{parsedSubtitle}</p>
                    )}

                    {!!paragraph && (
                        <p className={`m-0 ${paragraphAsDescriptionStyle ? "" :  "text-gray-600 leading-tight"}`}>{parsedParagraph}</p>
                    )}
                </div>
            )
        })
    }

    return (
        <TimelineItem lineType={lineType} className={`font-normal ${actionStatusBoxMode ? "text-sm" : "text-base"}`} iconComponent={iconComponent} iconClassName={actionStatusBoxMode ? "py-2" : ""}>
            <div className={`mb-2 w-full ${actionStatusBoxMode ? "bg-gray-50 rounded-lg p-2" : ""}`}>
                <div className={`text-gray-800 ${italicTitle ? "italic" : ""}`}>

                    <span className={`${lightTitle ? "" : "font-semibold"}`}>
                        {title}
                    </span>

                    {(!!dateTime && showRelativeTime && !datesAfterAll) && (
                        <span className='lowercase'>
                            &nbsp;
                            <FormattedRelativeTime date={dateTime} />
                        </span>
                    )}

                    {(!!dateTime && showAbsoluteTime && !datesAfterAll) && (
                        <span className='lowercase'>
                            {showRelativeTime && <span>{","}</span>}
                            &nbsp;
                            <span>{t('orders.list.at')}</span>
                            &nbsp;
                            <FormattedDate date={dateTime} shortDayMonthYearDateTime={true} />
                        </span>
                    )}

                    {(!!createdByName && showCreatedByName && !createdByNameAfterTitleComplement) && (
                        <span>
                            &nbsp;
                            {createdByName}
                        </span>
                    )}

                    {!!titleComplement && (
                        <span>
                            &nbsp;
                            {titleComplementComponent}
                        </span>
                    )}

                    {(!!createdByName && showCreatedByName && createdByNameAfterTitleComplement) && (
                        <span>
                            &nbsp;
                            {createdByName}
                        </span>
                    )}

                    {(!!dateTime && showRelativeTime && datesAfterAll) && (
                        <span className='lowercase'>
                            &nbsp;
                            <FormattedRelativeTime date={dateTime} />
                        </span>
                    )}

                    {(!!dateTime && showAbsoluteTime && datesAfterAll) && (
                        <span className='lowercase'>
                            {showRelativeTime && <span>{","}</span>}
                            &nbsp;
                            <span>{t('orders.list.at')}</span>
                            &nbsp;
                            <FormattedDate date={dateTime} shortDayMonthYearDateTime={true} />
                        </span>
                    )}

                    {!!titleTooltipMessage && (
                        <Tooltip
                            placement="top"
                            overlay={<span>{titleTooltipMessage}</span>}
                            arrowContent={<div></div>}
                            destroyTooltipOnHide
                        >
                            <div className="flex items-center">
                                <InformationCircleIcon className='ml-1.5 w-4 h-4 inline'/>
                            </div>
                        </Tooltip>
                    )}

                </div>

                {!!description && (
                    <div className="text-sm">
                        <span className={`${lightDescription ? "text-gray-600": ""} ${italicDescription ? "italic" : ""}`}>
                            {descriptionComponent}
                        </span>
                    </div>
                )}

                {paragraphArray?.length > 0 && (
                    <ul className="text-sm mt-1 ml-4 list-disc">
                        {paragraphArray.map(paragraphText => (
                            <li className={`m-0 ${lightDescription ? "text-gray-600": ""} ${italicDescription ? "italic" : ""}`}>
                                {`${paragraphText}.`}
                            </li>
                        ))}
                    </ul>
                )}

                {showParagraph && (
                    <div className={"flex text-sm mt-2"}>

                        {!!paragraphIconComponent && (
                            <div>
                                {paragraphIconComponent}
                            </div>
                        )}

                        <div className={`flex flex-col gap-2 ${paragraphIconComponent ? "ml-2 mt-1" : ""}`}>
                            {!!paragraphTitle && (
                                <p className="m-0">{paragraphTitle}</p>
                            )}

                            {(paragraphSubtitles?.length > 0) && buildParagraphs()}
                        </div>
                    </div>
                )}

                {actionButtonToShow && (
                    <div className="mt-2">
                        <ActionButton
                            buttonAction={actionButtonToShow}
                            orderId={orderId}
                            orderReturnId={orderReturnId}
                            dialog={dialog}
                            setDialog={setDialog}
                        />
                    </div>
                )}

                {actionStatusBoxMode && actionStatusBoxStatusResult && (
                    <div>
                        {`${t(`orders.orderHistory.status_title`)}: `}
                        <span className={`${actionStatusBoxStatusColor === statusColors.IN_REVIEW ? "text-pink-600" : actionStatusBoxStatusColor === statusColors.RESOLVED ? "text-green-600" : actionStatusBoxStatusColor === statusColors.REJECTED ? "text-red-600" : "text-gray-600"}`}>
                            {actionStatusBoxStatusResult}
                        </span>

                        {!!actionStatusBoxStatusDateTime && (
                            <span className="capitalize">
                                <span>{`. ${t('orders.list.at')}`}</span>
                                &nbsp;
                                <FormattedDate date={actionStatusBoxStatusDateTime} shortDayMonthYearDateTime={true} />
                            </span>
                        )}
                    </div>
                )}
            </div>
        </TimelineItem>
    )
}

// Main component
function OrderHistory({orderId, orderReturn, requiresExternalLabel, orderHistory, isLoadingData}) {
    const { t, i18n } = useTranslation()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })

    const onConfirmDialogHandler = async () => {
        setDialog({...dialog, loading: true})
        switch (dialog.type){
            case "paid":
                await payOrder(dialog.order_id)
                break
            case "hold":
                await holdOrder(dialog.order_id)
                break
            case "unhold":
                await unholdOrder(dialog.order_id)
                break
            default:
                break
        }
        setDialog({...dialog, loading: false, open: false})
    }

    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        type: null
    })

    // Filtered order history, which also contains the claims and shipping incidents (this ones are not originally included in order history)
    const filteredOrderHistory = useMemo(() => {
        if(orderHistory === null || orderHistory === undefined) return null
        orderHistory.order_histories.forEach((item) => {
            //If the order was interrupted after being shipped, we change the status to "shipment_interrupted"
            if(item.action === actions.ORDER_STATUS_UPDATE && item.new_status === statuses.INTERRUPTED && item.previous_status === statuses.SHIPPED)
                item.new_status = "shipment_interrupted"
        })

        const defaultOrderHistory = {
            created_by_cubbo_user: false,
            created_by: null,
        }

        // Convert claims into order history format
        const orderClaims = orderHistory.claims.map(({status, category, created_at, resolved_at, updated_at}) => (
            {
                ...defaultOrderHistory,
                action: virtualActions.CLAIM,
                metadata: {
                    status: status,
                    category: category,
                    resolved_at: resolved_at,
                    updated_at: updated_at,
                },
                reference_timestamp: created_at,
            }
        ))

        // Convert shipping incidents into order history format
        const orderShippingIncidents = orderHistory.shipping_incidents.filter(({category}) => (
            !DEPRECATED_SHIPPING_LABEL_CATEGORIES.includes(category)
        )).map(({status, category, created_at, resolved_at, resolution, updated_at}) => (
            {
                created_by_cubbo_user: origin === "CUBBO",
                action: virtualActions.SHIPPING_INCIDENT,
                metadata: {
                    status: status,
                    category: category,
                    resolved_at: resolved_at,
                    resolution: resolution,
                    updated_at: updated_at,
                },
                reference_timestamp: created_at,
            }
        ))

        const completeOrderHistory = [...orderHistory.order_histories, ...orderClaims, ...orderShippingIncidents]

        const sortedOrderHistory = completeOrderHistory?.sort((a, b) => new Date(a.reference_timestamp) - new Date(b.reference_timestamp))

        // TODO: Filter unknown /statuses

        const filteredHistory = sortedOrderHistory.filter((currentItem, index, items) => {
            // Other actions can be consecutively repeated
            if(currentItem.action !== actions.TRACKING_UPDATE && currentItem.action !== actions.ORDER_STATUS_UPDATE || currentItem.new_status == statuses.ERROR) return true

            if(index === 0) return true

            // Filter unique items
            switch(currentItem.new_status) {
                case statuses.SHIPMENT_COLLECTED:
                    return index === items.findIndex(compareItem => (
                        compareItem.new_status === currentItem.new_status && compareItem.action === currentItem.action
                    ))

                case statuses.SHIPMENT_IN_TRANSIT:
                    return currentItem.previous_status === statuses.SHIPMENT_COLLECTED && currentItem.action === actions.TRACKING_UPDATE ? false : true

                default:
                break
            }

            // Statuses that can be repeated
            switch(true) {
                case currentItem.new_status === statuses.SHIPMENT_WITH_INCIDENT && currentItem.action === actions.TRACKING_UPDATE:{
                    return true
                }

                default:
                break
            }

            return currentItem.new_status !== items[index - 1].new_status
        })

        const shipmentCollectedItemIndex = filteredHistory.findIndex(({new_status, action}) => (
            new_status === statuses.SHIPMENT_COLLECTED && action === actions.TRACKING_UPDATE
        ))

        if(shipmentCollectedItemIndex >= 0){
            let inTransitDateTime = new Date(filteredHistory[shipmentCollectedItemIndex].reference_timestamp)
            inTransitDateTime.setTime(inTransitDateTime.getTime() + 1)
            inTransitDateTime = inTransitDateTime.toISOString()

            const shipmentInTransitItem = {
                ...filteredHistory[shipmentCollectedItemIndex],
                new_status: statuses.SHIPMENT_IN_TRANSIT,
                previous_status: statuses.SHIPMENT_COLLECTED,
                reference_timestamp: inTransitDateTime
            }

            filteredHistory.splice(shipmentCollectedItemIndex + 1, 0, shipmentInTransitItem)
        }

        return filteredHistory

        /*
        // TODO: Only for reference, remove later
        return sortedOrderHistory.filter((currentItem, index, items) => {
            if(currentItem.action !== actions.TRACKING_UPDATE) return true

            return index === items.findIndex(compareItem => (
                compareItem.new_status === currentItem.new_status && compareItem.action === currentItem.action
            ))
        }).filter((currentItem, index, items) => {
            if(currentItem.action !== actions.ORDER_STATUS_UPDATE) return true

            return index === items.findIndex(compareItem => (
                compareItem.new_status === currentItem.new_status && compareItem.action === currentItem.action
            ))
        })*/
    }, [orderHistory])

    // Order history elements configurations
    const elementsConfigs = useMemo(() => {
        if(!filteredOrderHistory || filteredOrderHistory.length === 0) return null

        const getNextLineType = (prevLineType, itemIndex, totalItems) => {
            const isLastElement = itemIndex === totalItems - 1
            let lineType

            if(totalItems === 1) {  // If there is only 1 history element
                lineType = LINE_TYPE_NONE
            }
            else if(prevLineType === LINE_TYPE_END && isLastElement){ // If is the last history element and the previous one is end
                lineType = LINE_TYPE_NONE
            }
            else if(prevLineType === LINE_TYPE_NONE && isLastElement){ // If is the last history element and the previous one NONE
                lineType = LINE_TYPE_NONE
            }
            else if(isLastElement) { // If is the last history element
                lineType = LINE_TYPE_END
            }
            else if(!prevLineType || prevLineType === LINE_TYPE_END || prevLineType === LINE_TYPE_NONE){
                lineType = LINE_TYPE_START
            }
            else if(itemIndex === 0) { // If is the first history element
                lineType = LINE_TYPE_START
            }
            else {
                lineType = LINE_TYPE_MIDDLE
            }

            return lineType
        }

        const getDefaultItemConfig = (item, itemIndex, totalItems, prevElementConfig) => {
            const translationKey = item.action === actions.TRACKING_UPDATE || item.action === actions.ORDER_STATUS_UPDATE ? item.new_status : item.action

            let titleTranslationKey = `${translationPrefix}.${translationKey}.title`

            if(item?.origin === origins.CUBBO_TRACKING_APP && item.action === actions.EDIT){
                titleTranslationKey = `${translationPrefix}.tracking_${translationKey}.title`
            }

            const titleComplementTranslationKey = `${translationPrefix}.${translationKey}.title_complement`
            const titleComplementLinkTranslationKey = `${translationPrefix}.${translationKey}.title_complement_link`

            const descriptionTranslationKey = `${translationPrefix}.${translationKey}.description`
            const descriptionLinkTranslationKey = `${translationPrefix}.${translationKey}.description_link`

            const paragraphTitleKey = `${translationPrefix}.${translationKey}.paragraph.title`
            const paragraphContentPrefix = `${translationPrefix}.${translationKey}.paragraph.content`

            const titleTooltipTranslationKey = `${translationPrefix}.${translationKey}.title_tooltip`

            let paragraphSubtitles = null
            let paragraphContents = null

            // If at least one of the paragraph content keys exists, then we build the paragraphs
            if(i18n.exists(`${paragraphContentPrefix}.p1.title`) || i18n.exists(`${paragraphContentPrefix}.p1.description`)) {
                const max_paragraphs = 3

                paragraphSubtitles = []
                paragraphContents = []

                for (let paragraph = 1; paragraph <= max_paragraphs; paragraph++) {
                    const currentParagraphKey= `${paragraphContentPrefix}.p${paragraph}`
                    const paragraphSubtitleKey = `${currentParagraphKey}.title`
                    const paragraphDescriptionKey = `${currentParagraphKey}.description`

                    const subtitle = i18n.exists(paragraphSubtitleKey) ? `${t(paragraphSubtitleKey)}` : null
                    const description = i18n.exists(paragraphDescriptionKey) ? `${t(paragraphDescriptionKey)}` : null

                    if(!subtitle && !description) { break }

                    paragraphSubtitles.push(subtitle)
                    paragraphContents.push(description)
                }
            }


            const {lineType: prevLineType} = prevElementConfig || {}
            const lineType = getNextLineType(prevLineType, itemIndex, totalItems)

            let createdByName = null

            // Created by some user
            if(item?.created_by?.email) {
                createdByName = `${t("orders.orderHistory.action_by").toLowerCase()} ${item?.created_by?.email}`
            }
            else if(item?.origin === origins.INTEGRATION_WEBHOOK) {
                createdByName = `${t("orders.orderHistory.action_by").toLowerCase()} ${t("orders.orderHistory.by_integration")}`
            }
            else if (item?.origin === origins.CUBBO_TRACKING_APP) {
                createdByName = t("orders.orderHistory.from_tracking")
            }
            else {
                createdByName = `${t("orders.orderHistory.action_by").toLowerCase()} Cubbo`
            }

            return {
                title: i18n.exists(titleTranslationKey) ? t(titleTranslationKey) : null,
                createdByName: createdByName,

                titleComplement: i18n.exists(titleComplementTranslationKey) ? t(titleComplementTranslationKey) : null,
                titleComplementWordToLink: i18n.exists(titleComplementLinkTranslationKey) ? t(titleComplementLinkTranslationKey) : null,

                description: i18n.exists(descriptionTranslationKey) ? t(descriptionTranslationKey) : null,
                descriptionWordToLink: i18n.exists(descriptionLinkTranslationKey) ? t(descriptionLinkTranslationKey) : null,

                lineType: lineType,
                icon: itemIndex === totalItems - 1 ? icons.BOLD_DOT : icons.DOT,
                dateTime: item.reference_timestamp,

                paragraphTitle: i18n.exists(paragraphTitleKey) ? t(paragraphTitleKey) : null,

                paragraphSubtitles: paragraphSubtitles,
                paragraphContents: paragraphContents,

                titleTooltipMessage: i18n.exists(titleTooltipTranslationKey) ? t(titleTooltipTranslationKey) : null,

                preserveLineType: false,
            }
        }

        const buildTrackingUpdateConfiguration = (item, itemIndex, totalItems, prevElementConfig, lastItem) => {
            const defaultItemConfiguration = getDefaultItemConfig(item, itemIndex, totalItems, prevElementConfig)

            const {new_status, metadata} = item

            switch (new_status) {

                case statuses.SHIPMENT_CANCELED:
                case statuses.SHIPMENT_OUT_FOR_DELIVERY:
                case statuses.SHIPMENT_TO_BE_SELF_COLLECTED:
                    const showDescription = lastItem?.new_status === new_status

                    return {
                        ...defaultItemConfiguration,
                        description: showDescription ? defaultItemConfiguration.description : null,
                    }

                case statuses.SHIPMENT_DELIVERED:
                    return {
                        ...defaultItemConfiguration,
                    }

                case statuses.SHIPMENT_RETURNED:
                    return {
                        ...defaultItemConfiguration
                    }
                    
                case statuses.SHIPMENT_IN_TRANSIT:
                    return {
                        ...defaultItemConfiguration,
                    }

                case statuses.SHIPMENT_COLLECTED:{
                    let historyElements

                    const shippedHistoryElement = {
                        ...defaultItemConfiguration,
                        italicDescription: true,
                        lightDescription: true,
                        lineType: itemIndex > 0 ? LINE_TYPE_END : LINE_TYPE_NONE,
                    }

                    // Returns the history element with or without the external label history item
                    if(!requiresExternalLabel){
                        historyElements = [
                            {
                                ...shippedHistoryElement,
                                description: null,
                            },
                            {
                                ...shippedHistoryElement,
                                description: null,
                                title: shippedHistoryElement.description,
                                showAbsoluteTime: false,
                                lightTitle: true,
                                italicTitle: true,
                                lineType: LINE_TYPE_NONE,
                                icon: icons.TRUCK,
                                preserveLineType: true,
                            }
                        ]
                    } else {
                        const translationKey = "orders.orderHistory.timelineElements.shipment_external_label"

                        historyElements = [
                            {
                                ...shippedHistoryElement,
                            },
                            {
                                ...defaultItemConfiguration,
                                lineType: LINE_TYPE_START,
                                title: t(`${translationKey}.title`),
                                description: null,
                                lightTitle: true,
                                showAbsoluteTime: false,
                            }
                        ]
                    }

                    return historyElements}

                case statuses.SHIPMENT_WITH_INCIDENT:{
                    const { description } = metadata || {}

                    const incidentDescription = description && TRACKING_DESCRIPTIONS.has(description) && i18n.exists(`statuses.tracking_descriptions.${description}`) ? t(`statuses.tracking_descriptions.${description}`) : null

                    const historyElement = {
                        ...defaultItemConfiguration,
                        description: incidentDescription
                    }

                    if(Object.values(DELIVERY_ATTEMPT_DESCRIPTIONS).includes(description)){
                        historyElement.title = t(`${translationPrefix}.shipment_with_incident.title_delivery_attempt`)
                    }

                    return historyElement
                }

                default:
                    return null
            }
        }

        const buildOrderStatusUpdateConfiguration = (item, itemIndex, totalItems, prevElementConfig, lastItem) => {
            const defaultItemConfiguration = getDefaultItemConfig(item, itemIndex, totalItems, prevElementConfig)

            const {new_status, metadata, origin} = item

            switch (new_status) {
                case statuses.INITIAL:
                    return {
                        ...defaultItemConfiguration,
                        title: itemIndex === totalItems - 1 ? t(`${translationPrefix}.${new_status}.titleAlternative`) : defaultItemConfiguration.title,
                    }

                case statuses.CANCELATION_WINDOW:
                    return {
                        ...defaultItemConfiguration,
                        description: lastItem?.new_status === new_status ? defaultItemConfiguration.description : null,
                    }

                case statuses.SHIPPED:{
                    if(metadata?.shipping_method?.delivery_type === 'Pickup') {
                        return {
                            ...defaultItemConfiguration,
                            title: t(`${translationPrefix}.${new_status}.title_pickup`),
                            description: null,
                        }
                    }

                    return {
                        ...defaultItemConfiguration,
                        description: lastItem?.new_status === new_status ? defaultItemConfiguration.description : null,
                    }
                }

                case statuses.PENDING:
                case statuses.ENTIRELY_DROPSHIPPING:
                case statuses.PROCESSING:
                case statuses.EMPTY:
                    return {
                        ...defaultItemConfiguration,
                    }

                case statuses.ERROR:{
                    const allowDuplicateErrors = [
                        ORDER_ERRORS.UNIDENTIFIED_PRODUCT,
                        ORDER_ERRORS.DUPLICATE_STORE_SKU,
                        ORDER_ERRORS.INVALID_PRODUCT_INVOICING_PRICE,
                        ORDER_ERRORS.INVALID_PRODUCT_NCM,
                    ]

                    let errorMessages = null

                    if(metadata && Array.isArray(metadata)) {
                        // Filter unique error codes with exception of the allowed ones in "allowDuplicateErrors" array and build their messages
                        errorMessages = metadata?.filter(({code}, index, array) => (
                            allowDuplicateErrors.includes(code) || index === array.findIndex(({code: storedCode}) => storedCode === code)
                        ))?.map(metadataError => (
                            buildMessageForOrderHistoryError(metadataError, t, i18n)
                        ))
                    }

                    return {
                        ...defaultItemConfiguration,
                        description: null,
                        paragraphArray: errorMessages,
                    }}

                    // TODO: Only for reference, remove later
                    /*
                    let isMissingSkuFound = false

                    const generalErrorsTextList = metadata?.length > 0 ? metadata.map(({code, additional_info, field}) => {
                        switch (code) {
                            case ORDER_ERRORS.UNIDENTIFIED_PRODUCT:
                            case ORDER_ERRORS.MISSING_PRODUCT_SKU:
                                if(!additional_info?.sku && !isMissingSkuFound){
                                    isMissingSkuFound = true
                                    return t(`${translationPrefix}.${new_status}.missingInfoList.sku`)
                                }
                                return null

                            case ORDER_ERRORS.INVALID_ZIPCODE:
                                return t(`${translationPrefix}.${new_status}.missingInfoList.cp`)

                            case ORDER_ERRORS.MISSING_SHIPPING_FIELD:
                                if(field === "shipping_first_name"){
                                    return t(`${translationPrefix}.${new_status}.missingInfoList.name`)
                                } else if (field === "shipping_state") {
                                    return t(`${translationPrefix}.${new_status}.missingInfoList.state`)
                                } else if (field === "shipping_city") {
                                    return t(`${translationPrefix}.${new_status}.missingInfoList.city`)
                                } else if (field === "shipping_address_1") {
                                    return t(`${translationPrefix}.${new_status}.missingInfoList.address`)
                                }
                            break

                            default:
                            return null
                        }

                        return null
                    }).filter(content => content !== null)?.join(", ") : ""

                    const multipleInvalidSkuTestList = metadata?.length > 0 ? metadata.map(({code, additional_info, field}) => {
                        switch (code) {
                            case ORDER_ERRORS.UNIDENTIFIED_PRODUCT:
                            case ORDER_ERRORS.MISSING_PRODUCT_SKU:
                                return additional_info?.sku ? additional_info?.sku : null

                            default:
                            return null
                        }
                    }).filter(content => content !== null)?.join(", ") : ""

                    let fullDescription = generalErrorsTextList

                    if(generalErrorsTextList !== "" || multipleInvalidSkuTestList !== "") {

                        let specificSkuText = ""
                        fullDescription = generalErrorsTextList !== "" ? `${defaultItemConfiguration.description}: ${generalErrorsTextList}` : `${defaultItemConfiguration.description}: `

                        if(multipleInvalidSkuTestList !== "") {
                            const translationText = t(`${translationPrefix}.${new_status}.missingInfoList.invalid_sku`)
                            specificSkuText = translationText.replace("{sku}", multipleInvalidSkuTestList)
                            specificSkuText = generalErrorsTextList !== "" ? `, ${specificSkuText}` : specificSkuText
                        }

                        fullDescription =  fullDescription + specificSkuText
                    }

                    return {
                        ...defaultItemConfiguration,
                        description: fullDescription,
                    }}*/

                    /*
                    // TODO: Only for reference, remove later
                    const {invalidSkuList, missingInfoList} = metadata || {}
                    const textArray = []
                    let fullDescription = null

                    // General missing info
                    textArray.push(missingInfoList?.map(missingInfo => {
                        const translationKey = `${translationPrefix}.${new_status}.missingInfoList.${missingInfo}`
                        const translationText = i18n.exists(translationKey) ? t(translationKey) : ""

                        return translationText
                    }).join(", "))

                    // Specific missing sku
                    textArray.push(invalidSkuList?.map(invalidSku => {
                        const translationText = t(`${translationPrefix}.${new_status}.missingInfoList.invalid_sku`)

                        return translationText.replace("{sku}", invalidSku)
                    }).join(", "))

                    const filteredTextArray = textArray.filter(text => text && text !== "")

                    if(filteredTextArray.length > 0) {
                        fullDescription = `${defaultItemConfiguration.description}: ${filteredTextArray.join(", ")}`
                    }

                    return {
                        ...defaultItemConfiguration,
                        description: fullDescription,
                    }}*/

                case statuses.CANCELED:
                    const is_automation = origin === origins.CUBBO_AUTOMATION

                    return [
                        {
                            ...defaultItemConfiguration,
                            icon: icons.CLOSE,
                            titleComplement: is_automation ? defaultItemConfiguration.titleComplement : null,
                            titleTooltipMessage: is_automation ? defaultItemConfiguration.titleTooltipMessage : null,
                            lineType: LINE_TYPE_MIDDLE,
                            italicTitle: true,
                            lightTitle: true,
                            showCreatedByName: true,
                            showAbsoluteTime: false,
                        },
                        {
                            ...defaultItemConfiguration,
                            titleComplement: null,
                            titleTooltipMessage: null,
                        }
                    ]

                case statuses.BACKORDER:{
                    const {sku_list} = metadata || {}
                    const skuListAsText = sku_list && sku_list.length > 0 ? defaultItemConfiguration.description.replace('{sku}', sku_list.join(', ')) : null
                    const showParagraph = lastItem?.new_status === new_status

                    return {
                        ...defaultItemConfiguration,
                        showParagraph: showParagraph,
                        description: skuListAsText,
                        paragraphIcon: icons.BULB,
                    }}

                case statuses.UNPAID:
                    // TODO: Get store automations and check if its has a cancel configuration

                    const showPayButton = lastItem?.new_status === new_status

                    return{
                        ...defaultItemConfiguration,
                        actionButtonToShow: showPayButton ? buttonActions.PAY_ORDER : null,
                    }

                case statuses.HOLD:
                    const showUnholdButton = lastItem?.new_status === new_status

                    return [
                        {
                            ...defaultItemConfiguration,
                            icon: icons.PAUSE,
                            lineType: LINE_TYPE_MIDDLE,
                            italicTitle: true,
                            lightTitle: true,
                            showCreatedByName: true,
                            showAbsoluteTime: false,
                        },
                        {
                            ...defaultItemConfiguration,
                            actionButtonToShow: showUnholdButton ? buttonActions.UNHOLD_ORDER : null
                        }
                    ]
                case statuses.SHIPMENT_INTERRUPTED:
                case statuses.INTERRUPTED:
                    return {
                        ...defaultItemConfiguration,
                        showCreatedByName: true,
                    }

                case statuses.RETURNING:{
                    let {return_type} = orderHistory?.return || {}
                    if (metadata?.reason === "shipment_interrupted")
                        return_type = "SHIPMENT_INTERRUPTED" 
                    const showAdditionalInfo = lastItem?.new_status === new_status
                    const descriptionPrefix = `${translationPrefix}.${new_status}.types.${return_type}`
                    const description = return_type && i18n.exists(descriptionPrefix) && t(descriptionPrefix) !== "" ? t(descriptionPrefix) : null

                    return{
                        ...defaultItemConfiguration,
                        description: showAdditionalInfo ? description : null,
                        showCreatedByName: return_type === "SHIPMENT_INTERRUPTED" ? true : false,
                        actionButtonToShow: showAdditionalInfo ? buttonActions.VIEW_RETURN : null,
                    }}
                case statuses.RETURN_ARRIVED:{
                    const {return_type} = orderHistory?.return || {}
                    const showAdditionalInfo = lastItem?.new_status === new_status
                    const descriptionPrefix = `${translationPrefix}.${new_status}.types.${return_type}`
                    const description = return_type && i18n.exists(descriptionPrefix) && t(descriptionPrefix) !== "" ? t(descriptionPrefix) : null

                    return{
                        ...defaultItemConfiguration,
                        description: showAdditionalInfo ? description : null,
                        showCreatedByName: false,
                        actionButtonToShow: showAdditionalInfo ? buttonActions.VIEW_RETURN : null,
                    }}
                case statuses.RETURNED:{
                    const {return_type} = orderHistory?.return || {}
                    const showAdditionalInfo = lastItem?.new_status === new_status
                    const descriptionPrefix = `${translationPrefix}.${new_status}.types.${return_type}`
                    const description = return_type && i18n.exists(descriptionPrefix) && t(descriptionPrefix) !== "" ? t(descriptionPrefix) : null

                    return{
                        ...defaultItemConfiguration,
                        description: showAdditionalInfo ? description : null,
                        showCreatedByName: false,
                        actionButtonToShow: showAdditionalInfo ? buttonActions.VIEW_RETURN : null,
                    }}

                case statuses.REENTERED:{
                    const {return_type} = orderHistory?.return || {}
                    const showAdditionalInfo = lastItem?.new_status === new_status
                    const descriptionPrefix = `${translationPrefix}.${new_status}.types.${return_type}`
                    const description = return_type && i18n.exists(descriptionPrefix) && t(descriptionPrefix) !== "" ? t(descriptionPrefix) : null

                    return{
                        ...defaultItemConfiguration,
                        description: showAdditionalInfo ? description : null,
                        showCreatedByName: false,
                        actionButtonToShow: showAdditionalInfo ? buttonActions.VIEW_RETURN : null,
                    }}

                default:
                    return null
            }
        }

        const buildElementConfig = (item, itemIndex, totalItems, prevElementConfig, lastItem) => {
            const defaultItemConfiguration = getDefaultItemConfig(item, itemIndex, totalItems, prevElementConfig)

            const {action, metadata, origin, created_by_cubbo_user} = item
            const isLastItem = itemIndex === totalItems - 1

            switch (action) {
                case actions.CREATE:{
                    let configuration

                    if(origin === origins.CUBBO_USER || origin === origins.CUBBO_USER_IMPORT) {
                        configuration = [
                            {
                                ...defaultItemConfiguration,
                                showCreatedByName: true,
                                icon: icons.NEW,
                                italicTitle: true,
                                lightTitle: true,
                                lineType: defaultItemConfiguration.lineType === LINE_TYPE_NONE ? LINE_TYPE_START : defaultItemConfiguration.lineType,
                            },
                            {
                                ...defaultItemConfiguration,
                                title: isLastItem ? t(`${translationPrefix}.initial.title`) : t(`${translationPrefix}.initial.titleAlternative`),
                                lineType: isLastItem ? LINE_TYPE_END : LINE_TYPE_MIDDLE,
                            }
                        ]
                    } else {
                        configuration = {
                            ...defaultItemConfiguration,
                            title: isLastItem ? t(`${translationPrefix}.initial.title`) : t(`${translationPrefix}.initial.titleAlternative`)
                        }
                    }

                    return configuration
                }

                case actions.PAID:
                    if(origin !== origins.CUBBO_USER) { return null }

                    return {
                        ...defaultItemConfiguration,
                        title: t(`${translationPrefix}.${actions.PAID}.by_user`),
                        showCreatedByName: true,
                        icon: icons.MONEY,
                        italicTitle: true,
                        lightTitle: true,
                    }

                case actions.UNHOLD:
                    return {
                            ...defaultItemConfiguration,
                            icon: icons.PLAY,
                            lineType: LINE_TYPE_MIDDLE,
                            italicTitle: true,
                            lightTitle: true,
                            showCreatedByName: true,
                    }

                case actions.EDIT:{
                    if(origin !== origins.CUBBO_USER && origin !== origins.INTEGRATION_WEBHOOK && origin !== origins.CUBBO_TRACKING_APP) { return null }

                    return {
                            ...defaultItemConfiguration,
                            icon: icons.EDIT,
                            italicTitle: true,
                            lightTitle: true,
                            showCreatedByName: true,
                    }}

                case actions.RETURN:{
                    const description = metadata.return_by === "client" ? t(`${translationPrefix}.${actions.RETURN}.by_client`) : t(`${translationPrefix}.${actions.RETURN}.by_carrier`)

                    return{
                        ...defaultItemConfiguration,
                        containerCustomElement: (
                            <div className="flex flex-col">
                                <p className="m-0">{description}</p>

                                <ActionButton
                                    action={buttonActions.VIEW_RETURN}
                                    orderId={orderHistory?.id}
                                    orderReturnId={orderHistory?.return?.id}
                                    dialog={dialog}
                                    setDialog={setDialog}
                                />
                            </div>
                        )
                    }}

                case actions.CREATE_FROM:{
                    const {order_number, id: order_id} = metadata || {}

                    return [
                        {
                            ...defaultItemConfiguration,
                            icon: icons.COPY,
                            titleComplementWordToLink: order_number ? `#${order_number}` : null,
                            titleComplementLink: order_id ? (storePath + SHOW_ORDERS.replace(':id', order_id)) : null,
                            italicTitle: true,
                            lightTitle: true,
                            showCreatedByName: true,
                            isTitleComplementLinkLocal: true,
                            createdByNameAfterTitleComplement: true,
                            showAbsoluteTime: true,
                            lineType: defaultItemConfiguration.lineType === LINE_TYPE_NONE ? LINE_TYPE_START : defaultItemConfiguration.lineType,
                        },
                        {
                            ...defaultItemConfiguration,
                            title: isLastItem ? t(`${translationPrefix}.initial.title`) : t(`${translationPrefix}.initial.titleAlternative`),
                            lineType: isLastItem ? LINE_TYPE_END : LINE_TYPE_MIDDLE,
                            titleComplement: null,
                        }
                    ]
                }

                case actions.RESET_PENDING:
                    return {
                        ...defaultItemConfiguration,
                        icon: icons.PLAY,
                        lineType: LINE_TYPE_MIDDLE,
                        italicTitle: true,
                        lightTitle: true,
                        showCreatedByName: true,
                        title: t(`${translationPrefix}.reset_pending.title`),
                    }

                case actions.DUPLICATE:{
                    const {order_number, id: order_id} = metadata || {}

                    return {
                            ...defaultItemConfiguration,
                            icon: icons.COPY,
                            italicTitle: true,
                            lightTitle: true,
                            showCreatedByName: true,
                            description: order_number ? defaultItemConfiguration.description : null,
                            descriptionWordToLink: order_number ? `#${order_number}` : null,
                            descriptionLink: order_id ? (storePath + SHOW_ORDERS.replace(':id', order_id)) : null,
                            isDescriptionLinkLocal: true,
                        }}

                case virtualActions.CLAIM:{
                    const {status, category, updated_at, resolved_at} = metadata || {}
                    const statusDateTime = status === "ACCEPTED" ? resolved_at : updated_at

                    return {
                        ...defaultItemConfiguration,
                        icon: icons.ROLLBACK,
                        actionStatusBoxMode: true,
                        lightTitle: true,
                        title: `${defaultItemConfiguration.title}: ${t(`${translationPrefix}.${virtualActions.CLAIM}.categories.${category}`)}`,
                        actionStatusBoxStatusColor: status === "REJECTED" ? statusColors.REJECTED : status === "ACCEPTED" ? statusColors.RESOLVED : statusColors.PENDING,
                        actionStatusBoxStatusResult: t(`${translationPrefix}.${virtualActions.CLAIM}.statuses.${status}`),
                        actionStatusBoxStatusDateTime: statusDateTime,
                    }}

                case virtualActions.SHIPPING_INCIDENT:{
                    const {status, category, resolution, updated_at, resolved_at} = metadata || {}
                    const incidentsPrefix = `orders.show_shipping_incidents`
                    const resolutionComplement = status !== "RESOLVED" ? "" : t(`${incidentsPrefix}.resolutions_complements.${resolution}.${category}`)
                    const statusDateTime = status === "RESOLVED" ? resolved_at : updated_at

                    return {
                        ...defaultItemConfiguration,
                        icon: icons.FLAG,
                        actionStatusBoxMode: true,
                        lightTitle: true,
                        title: t(`${incidentsPrefix}.categories.${category}`),
                    }}

                case actions.TRACKING_UPDATE:
                    return buildTrackingUpdateConfiguration(item, itemIndex, totalItems, prevElementConfig, lastItem)

                case actions.ORDER_STATUS_UPDATE:
                    return buildOrderStatusUpdateConfiguration(item, itemIndex, totalItems, prevElementConfig, lastItem)

                default:
                    return null
            }
        }

        const lastItem = filteredOrderHistory[filteredOrderHistory.length - 1]
        let prevElementConfig = null

        const timelineElementsConfigs = filteredOrderHistory?.flatMap((item, itemIndex, array) => {

            const elementConfig = buildElementConfig(item, itemIndex, array.length, prevElementConfig, lastItem)

            // Save the last element configuration
            if(elementConfig && Array.isArray(elementConfig)) {
                // Filter configs
                const validConfigs = elementConfig.filter(config => config !== null)
                // Set the last valid config
                prevElementConfig = validConfigs.length > 0 ? {...validConfigs[validConfigs.length - 1]} : prevElementConfig
            } else if(elementConfig && typeof elementConfig === 'object') {
                prevElementConfig = {...elementConfig}
            }

            return elementConfig
        }).filter(item => item !== null)

        // Fix last line type
        if(timelineElementsConfigs.length > 0){
            const lastIndex = timelineElementsConfigs.length - 1
            const previousIndex = lastIndex === 0 ? null : lastIndex - 1
            const preserveLastLineType = timelineElementsConfigs[lastIndex].preserveLineType

            // If there is only 1 element
            if(previousIndex === null){
                timelineElementsConfigs[lastIndex].lineType = LINE_TYPE_NONE
            }
            else if(!preserveLastLineType && timelineElementsConfigs[previousIndex].lineType === LINE_TYPE_NONE){
                timelineElementsConfigs[lastIndex].lineType = LINE_TYPE_NONE
            }
            else if(!preserveLastLineType){
                timelineElementsConfigs[lastIndex].lineType = LINE_TYPE_END
            }
        }

        return timelineElementsConfigs
    },[dialog, filteredOrderHistory, i18n, orderHistory, requiresExternalLabel, storePath, t])

    // Order history timeline elements components
    const timelineElements = useMemo(() => {
        if(!elementsConfigs || elementsConfigs.length === 0) return null

        return elementsConfigs?.map((elementConfig, index) => (
                    <HistoryElement
                        key={index}
                        orderId={orderHistory?.id}
                        orderReturnId={orderHistory?.return?.id}
                        dialog={dialog}
                        setDialog={setDialog}
                        {...elementConfig }
                    />
                ))
    }, [dialog, elementsConfigs, orderHistory])

    // TODO: Remove this useEffect, it's only for debugging
    // useEffect(() => {
    //     console.log("orderHistory", orderHistory)
    //     console.log("filteredOrderHistory", filteredOrderHistory)
    //     console.log("elementsConfigs", elementsConfigs)
    // },[elementsConfigs, filteredOrderHistory, orderHistory])

    return (
        <div className="">
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({...dialog, open: value}) }
                title={dialog.title}
                description={dialog.description}
                confirmLabel="Si"
                onConfirm={onConfirmDialogHandler}
                loading={dialog.loading}
            />


            <div className="pl-5">
                <SkeletonLoader show={isLoadingData} />
            </div>

            {(!(timelineElements?.length > 0) && !isLoadingData) && (
                <p className="text-gray-700 font-normal text-base m-0 pl-6">{t("orders.orderHistory.no_history")}</p>
            )}

            {(timelineElements?.length > 0 && !isLoadingData) && (
                <section>
                    {timelineElements?.map((element, index) => element)}
                </section>
            )}
        </div>
    )
}

export default OrderHistory
