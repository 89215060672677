import { ButtonLoader, Notification, InputField, Button, SearchInput, ActionMenu} from "../../components"
import {useState, useContext, componentDidMount, useEffect} from "react";
import { Loader } from '../../components/Loader';
import {useQuery} from "react-query";
import { UserContext } from "../../hooks/UserContext";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { fetchStoreInfo} from "../../services/storeServices"
import { fetchNcms} from "../../services/ncmsServices"
import { getTaxSituationCfop, getTaxSituationPis, getTaxSituationIpi } from '../../components/TaxDefinitions';
import { LeftCircleOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { CreateNCMsContainer } from './CreateNCMsContainer';
import { Collapse, Dropdown, Pagination, Menu } from 'antd';



export const NCMsSettingsContainer = ({
    onCancel
}
) => {


    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const {register, handleSubmit,formState: { errors }} = useForm({})
    const { t, i18n } = useTranslation();
    const pageSize = useSelector((state) => state.pageSize.ordersList);
    const dispatch = useDispatch()
    const {user} = useContext(UserContext)
    const [searchParams, setSearchParams] = useState({
        page: 1,
        store_id: user.current_store?.id,
        per_page: 10
    })
    const {
        isLoading,
        isError,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['store_info_ncms', searchParams], () => fetchNcms(searchParams), { keepPreviousData: true })

    const {
        isLoading: storeisLoading,
        isError: storeisError,
        error: storeerror,
        data: store,
        isPreviousData: storeisPreviousData,
    } = useQuery(['store_info_list_ncms', user.current_store?.id], () => fetchStoreInfo(user.current_store?.id), { keepPreviousData: true })

    const [showNotification, setShowNotification] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [ncmEdit, setNcmEdit] = useState(false)
    const [title, setTitle] = useState(false)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const { Panel } = Collapse;

    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)

    const handleClickOnCreate = () => {
        setNcmEdit(null)
       setShowCreate(true)
    }
    const handleClickOnEdit = (id) => {
        setShowCreate(true)
        setNcmEdit(id)
     }
    const handleClickOnCancel = () => {
        onCancel()
    }
    const handleClickOnCancelCreate = () => {
        setShowCreate(false)
        refetch()
    }
    const onSearchHandler = (v) => {
        setSearchParams({...searchParams, ncm: v})
    }
    const handleChange = (event, value) => {
        console.log(event,value)
        setSearchParams({...searchParams, page: event})
    };


    const editButton = (ncm) => {

        let options = [
            {
              title: i18n.t("settings.ncm_configurations.edit_tax") ,
              clickHandler: () => handleClickOnEdit(ncm)
            }
          ]

          const menu = (
            <Menu onClick={e => e.stopPropagation()}>
                {options.map((item, index)=>{
                    return (
                        <Menu.Item key={index} onClick={e => e.stopPropagation()}>
                            
                            <a className={"cursor-pointer " + (item.disabled === true ? 'opacity-50 ' : '')} onClick={!item.disabled && item.clickHandler}>
                                {item.title}
                            </a>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
          
        return(
            <Dropdown overlay={menu} placement="bottomLeft" className="relative inline-block text-left">
                <Button type="link"  >
                    <EllipsisOutlined style={{ fontSize: '1.5rem' }}/>
                </Button>
            </Dropdown>
        )
    };

    return (
        <div className='relative p-5'>

            
            {showCreate ? (
                <CreateNCMsContainer onCancel={handleClickOnCancelCreate} id={ncmEdit} edit={ncmEdit ? true : false} />
            ) : (
                <div className="space-y-1">
                    <div className="flex items-center justify-between">
                        <div  className="flex items-center justify-start">
                            <LeftCircleOutlined className="text-3xl mr-8 hover:text-indigo-700" onClick={handleClickOnCancel}/>
                            <div  className="flex-col">
                                <p className='text-xl mb-0 font-bold text-gray-900 mt-5'>{i18n.t("settings.billing_config.ncms_settings")} <span className = "text-red-500"> * </span> </p>
                                <p className = "mt-0 text-base text-gray-500">{i18n.t("settings.billing_config.ncms_settings_description")}</p>
                            </div>
                        </div>
                        <div>
                            <ButtonLoader loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton}  onClick={handleClickOnCreate}>
                                {i18n.t("settings.ncm_configurations.add_ncm")} 
                            </ButtonLoader>
                        </div>
                    </div>
                </div>
                
            )}
                <div className="mt-10">

                <p className = "mt-0 text-base font-bold text-gray-900"> {i18n.t("settings.ncm_configurations.ncm_configurations_store")} </p>
              
                        <div className="flex items-center justify-between">
                            <div  className="flex items-center justify-start">
                                <div className="mt-1 mb-5">
                                    <SearchInput onChange = {onSearchHandler} debounce={250} className="focus-within:text-gray-600 text-gray-400 "/>
                                </div>
                            </div>
                            <div>
                                {/* pending -> create component to use in system views. */}
                               {data?.meta &&  <Pagination size="small" total={data?.meta.total_count} onChange={handleChange} page={data?.meta.current_page}/> }
                            </div>
                        </div>

                {(!isPreviousData && isLoading) ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ): data?.ncm_configurations.length == 0 ? (
                   <p className = "mt-0 text-gray-500"> {i18n.t("settings.ncm_configurations.no_ncms_configured")}</p> 
                ):(
                    <>
                        <Collapse  bordered={true}  ghost>
                        {data?.ncm_configurations?.map((ncm, index) => (
        
        
                            <Collapse.Panel header={<label className="cursor-pointer"> <span className="font-bold">NCM: </span> {ncm.ncm} - {ncm.description} </label> } key={index} className="collapse-panel-ncms"  extra={editButton(ncm.id)} >
                                
                                {/* TODO: Show */}
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100">
                                        ICMS
                                    </div>
                                <Collapse ordered={true} defaultActiveKey={['1']} ghost>
                                    <Panel className="collapse-panel-custom" b header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="1">
                                    <div className="grid grid-cols-2 gap-x-8 gap-y-3">
                                        <div>
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_situation")} </p>
                                            <p>{getTaxSituationCfop(store?.tax_regime,ncm.cfop_tax_situation,true)}</p>
                                            {(ncm.cfop_tax_situation == 101 || ncm.cfop_tax_situation == 201) && <>
                                                <p className="font-bold mt-2">{i18n.t("settings.ncm_configurations.tax_rate_credit")}</p>
                                                <p>{ncm.icms_credit_tax_rate}</p>
                                            </>}
                                        </div>
                                        {store?.tax_regime != "SIMPLES_NACIONAL" &&
                                            <div>
                                                <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_situation_other_state")} </p>
                                                <p>{getTaxSituationCfop(store?.tax_regime,ncm.cfop_tax_situation_other_state,true)}</p>
                                            </div>
                                        }
                                        <div>
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_situation_international")} </p>
                                            <p>{getTaxSituationCfop(store?.tax_regime,ncm.cfop_tax_situation_international,true)}</p>
                                        </div>
                                    </div>
                                   <p className="font-bold mb-2 mt-8">cfop</p>  
                                   <p className="mb-1 text-gray-500"> {i18n.t("settings.ncm_configurations.order_invoices")}  <span className = "text-gray-600 italic font-normal">  {i18n.t("settings.ncm_configurations.nfs_note")}</span></p>
                                   <div className="border rounded-md">
                                   <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">
        
                                    <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center border-b border-gray-200 ">
                                        <div>
                                            <p className="mt-4 mb-4 pl-5 text-sm font-semibold">{i18n.t("settings.ncm_configurations.invoice_table_title")}</p>
                                        </div>
                                        <div >
                                            <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.same_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.other_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP <br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.international")}</label></p>
                                        </div>
                                    </div>
                                    <div className="divide-y divide-gray-200"></div>
                                    
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.symbolic_return")}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sym_return_invoice_same_state}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sym_return_invoice_other_state}</p>
                                            </div>
                                            <p className="mt-3">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.sale")}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sale_invoice_same_state}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sale_invoice_other_state}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sale_invoice_international}</p>
                                            </div>
                                        </div>
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.donation")}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_donation_invoice_same_state}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_donation_invoice_other_state}</p>
                                            </div>
                                            <p className="mt-3">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.remove_stock")}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sym_return_invoice_same_state}</p>
                                            </div>
                                            <div>
                                                <p className="mt-3 text-sm ">{ncm.cfop_sym_return_invoice_same_state}</p>
                                            </div>
                                            <p className="mt-3">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                         
                                    </div>
                                   </div>
                                   <p className="mb-1 mt-5 text-gray-500"> {i18n.t("settings.ncm_configurations.return_invoices")}</p>
                                   <div className="border rounded-md">
                                   <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">
        
                                   <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                        <div>
                                            <p className="mt-4 mb-4 pl-5 text-sm font-semibold">{i18n.t("settings.ncm_configurations.invoice_table_title")}</p>
                                        </div>
                                        <div >
                                            <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.same_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.other_state_entity")}</label></p>
                                        </div>
                                    </div>
                                    <div className="divide-y divide-gray-200"></div>
                                    
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.return")}</p>
                                            </div>
                                            <p className="mt-3 text-sm ">{ncm.cfop_return_invoice}</p>
                                            {ncm.cfop_return_invoice_other_state ?   
                                            <p className="mt-3 text-sm ">{ncm.cfop_return_invoice_other_state}</p> :
                                            <p className="mt-3 text-sm">{i18n.t("settings.ncm_configurations.dont_apply")}</p>}
                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.return_donation")}</p>
                                            </div>
                                            <p className="mt-3 text-sm ">{ncm.cfop_donation_return_invoice_same_state}</p>
                                            <p className="mt-3 text-sm ">{ncm.cfop_donation_return_invoice_other_state}</p>
                                        </div>
                                         
                                    </div>
                                   </div>
        
                                   <p className="mb-1 mt-5 text-gray-500"> {i18n.t("settings.ncm_configurations.replenihsment_invoices")} <span className = "text-gray-600 italic font-normal">  {i18n.t("settings.ncm_configurations.note")}</span></p>
                                   <div className="border rounded-md">
                                   <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">
        
                                   <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                        <div>
                                            <p className="mt-4 mb-4 pl-5 text-sm font-semibold">{i18n.t("settings.ncm_configurations.invoice_table_title")}</p>
                                        </div>
                                        <div >
                                            <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.same_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.other_state_entity")}</label></p>
                                        </div>
                                    </div>
                                    <div className="divide-y divide-gray-200"></div>
                                    
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment")}</p>
                                            </div>
                                            <p className="mt-3 text-sm ">{ncm.cfop_replenishment_invoice_same_state}</p>
                                            <p className="mt-3 text-sm ">{ncm.cfop_replenishment_invoice_other_state}</p>
                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment_adjustment")}</p>
                                            </div>
                                            <p className="mt-3 text-sm ">{ncm.cfop_replenishment_adjustment_invoice_same_state}</p>
                                            <p className="mt-3 text-sm ">{ncm.cfop_replenishment_adjustment_invoice_other_state}</p>
                                        </div>
                                         
                                    </div>
                                   </div>
        
                                    </Panel>
        
                                </Collapse>
        
                                </div>
        
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100">
                                        IPI
                                    </div>
                                <Collapse bordered={true} defaultActiveKey={['1']} ghost>
        
                                    <Collapse.Panel header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="1" className="collapse-panel-custom">
        
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 w-full justify-center ">
                                        <div >
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_situation")} </p>
                                            <p>{getTaxSituationIpi(ncm.ipi_tax_situation,true)}</p>
                                        </div>
                                        <div >
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_rate")}</p>
                                            <p>{ncm.ipi_tax_rate}</p>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <p className="font-bold">{i18n.t("settings.ncm_configurations.framing_code")}</p>
                                        <p>{ncm.ipi_framing_code}</p>
                                    </div> 
        
                                    </Collapse.Panel>
                                
                                </Collapse>
        
                                </div>
                            
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100">
                                        PIS
                                    </div>
                                <Collapse bordered={true} defaultActiveKey={['1']} ghost>
        
                                    <Panel className="collapse-panel-custom" header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="1" >
        
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 mb-4 w-full justify-center ">
                                        <div >
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_situation")} </p>
                                            <p>{getTaxSituationPis(ncm.pis_tax_situation,true)}</p>
                                        </div>
                                        <div >
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_rate")}</p>
                                            <p>{ncm.pis_tax_rate}</p>
                                        </div>
                                    </div> 
        
                                    </Panel>
                                
                                </Collapse>
        
                                </div>
                            
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-3.5 sm:grid-cols-2 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100">
                                    COFINS
                                    </div>
                                <Collapse bordered={true} defaultActiveKey={['1']} ghost>
        
                                    <Panel className="collapse-panel-custom" header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="1">
        
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 mb-4 w-full justify-center ">
                                        <div >
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_situation")} </p>
                                            <p>{getTaxSituationPis(ncm.cofins_tax_situation,true)}</p>
                                        </div>
                                        <div >
                                            <p className="font-bold">{i18n.t("settings.ncm_configurations.tax_rate")}</p>
                                            <p>{ncm.cofins_tax_rate}</p>
                                        </div>
                                    </div> 
        
                                    </Panel>
                                
                                </Collapse>
        
                                </div>
                            
                            </Collapse.Panel>
                        
                        ))}
                        </Collapse>

                    </>        
                )}



                </div>

        </div>
    )

}